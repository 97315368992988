<template>
  <div class="elv-report-transactions-container">
    <router-view></router-view>
  </div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'
import { usePageIntroHook } from '@/hooks/usePageIntroHook'
import { useReportStore } from '@/stores/modules/reports/index'
import { useTransactionStore } from '@/stores/modules/transactions'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const reportStore = useReportStore()
const transactionStore = useTransactionStore()

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})
watch(
  () => route,
  async (newValue) => {
    if (
      newValue.name === 'entity-transactions-list' ||
      newValue.name === 'entity-transactions-automation' ||
      newValue.name === 'entity-transactions-trade-list' ||
      newValue.name === 'entity-transactions-gain-or-loss'
    ) {
      transactionStore.fetchJournalTypeList(entityId.value)
      if (newValue.name === 'entity-transactions-list' || newValue.name === 'entity-transactions-automation') {
        entityStore.fetchChartOfAccountList(entityId.value)
      }
      nextTick(() => {
        if (!reportStore.guidanceUnDisplayList.includes('journalToLedger')) {
          setTimeout(() => {
            const steps = [
              {
                element: document.querySelector(
                  `.elv-layout-main-menu-children[data-entityid="${entityId.value}"] .elv-layout-main-menu-main-nav-box__name-soon[data-menu="ledger"]`
                ), // 这是添加引导的元素id
                intro: t('bubbleGuide.journalToLedger.step1'), // 这是引导提示内容
                position: 'right' // 这是引导位置
              }
            ]
            // allowDisplayGuidance.value = false
            usePageIntroHook(steps, 'journalToLedger')
          }, 500)
        }
      })
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-report-transactions-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
